:root {
  --mat-datepicker-calendar-text-size: 0.8125rem;
  --mat-datepicker-calendar-body-label-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 0.875rem;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 0.6875rem;
  --mat-datepicker-calendar-header-text-weight: 400;

  --mat-datepicker-calendar-date-selected-state-text-color: var(
    --text-neutral-invert
  );
  --mat-datepicker-calendar-date-selected-state-background-color: var(
    --text-brand-default
  );
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(
    111,
    143,
    47,
    0.4
  );
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(
    111,
    143,
    47,
    0.3
  );
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(
    111,
    143,
    47,
    0.3
  );
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(
    111,
    143,
    47,
    0.2
  );
  --mat-datepicker-toggle-active-state-icon-color: var(--text-brand-default);

  --mat-datepicker-calendar-date-text-color: var(--text-neutral-body);
  --mat-datepicker-calendar-header-text-color: var(--text-neutral-caption);
  --mat-datepicker-calendar-body-label-text-color: var(--text-neutral-caption);
  --mat-datepicker-calendar-date-today-outline-color: var(--text-neutral-body);
  --mat-datepicker-calendar-header-divider-color: var(--border-neutral-default);

  // MTX
  --mtx-datetimepicker-calendar-text-size: 0.8125rem;
  --mtx-datetimepicker-calendar-body-label-text-size: 0.875rem;
  --mtx-datetimepicker-calendar-body-label-text-weight: 500;
  --mtx-datetimepicker-calendar-period-button-text-size: 0.875rem;
  --mtx-datetimepicker-calendar-period-button-text-weight: 500;
  --mtx-datetimepicker-calendar-table-header-text-size: 0.6875rem;
  --mtx-datetimepicker-calendar-table-header-text-weight: 400;
  --mtx-datetimepicker-clock-text-size: 0.875rem;
  --mtx-datetimepicker-selector-container-shape: 0.25rem;

  --mtx-datetimepicker-container-elevation-shadow:
    0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mtx-datetimepicker-container-shape: 0.25rem;
  --mtx-datetimepicker-calendar-header-background-color: var(
    --surface-brand-default
  );
  --mtx-datetimepicker-calendar-date-selected-state-text-color: var(
    --text-neutral-invert
  );
  --mtx-datetimepicker-calendar-date-selected-state-background-color: var(
    --surface-brand-default
  );
  --mtx-datetimepicker-calendar-date-selected-disabled-state-background-color: var(
    --surface-brand-light
  );
  --mtx-datetimepicker-calendar-date-today-selected-state-outline-color: var(
    --text-neutral-invert
  );
  --mtx-datetimepicker-calendar-date-focus-state-background-color: var(
    --surface-brand-light
  );
  --mtx-datetimepicker-calendar-date-hover-state-background-color: var(
    --surface-brand-light
  );
  --mtx-datetimepicker-clock-hand-background-color: var(
    --surface-brand-default
  );
  --mtx-datetimepicker-clock-cell-selected-state-background-color: var(
    --surface-brand-default
  );
  --mtx-datetimepicker-time-input-active-state-text-color: var(
    --surface-brand-default
  );
  --mtx-datetimepicker-time-input-active-state-background-color: var(
    --surface-brand-light
  );
  --mtx-datetimepicker-time-input-focus-state-outline-color: var(
    --surface-brand-default
  );
  --mtx-datetimepicker-time-input-focus-state-placeholder-text-color: var(
    --surface-brand-darker
  );
  --mtx-datetimepicker-time-ampm-selected-state-background-color: var(
    --surface-brand-default
  );
  --mtx-datetimepicker-toggle-active-state-icon-color: var(
    --surface-brand-default
  );
  --mtx-datetimepicker-toggle-icon-color: var(--text-neutral-body);
  --mtx-datetimepicker-calendar-body-label-text-color: var(--text-neutral-body);
  --mtx-datetimepicker-calendar-header-text-color: var(--text-neutral-invert);
  --mtx-datetimepicker-calendar-header-divider-color: transparent;
  --mtx-datetimepicker-calendar-table-header-text-color: var(
    --text-neutral-body
  );
  --mtx-datetimepicker-calendar-date-today-outline-color: var(
    --border-neutral-darker
  );
  --mtx-datetimepicker-calendar-date-text-color: var(--text-neutral-body);
  --mtx-datetimepicker-calendar-date-outline-color: transparent;
  --mtx-datetimepicker-calendar-date-disabled-state-text-color: var(
    --text-neutral-disabled
  );
  --mtx-datetimepicker-container-background-color: var(--surface-neutral-light);
  --mtx-datetimepicker-container-text-color: var(--text-neutral-body);
  --mtx-datetimepicker-clock-dial-background-color: rgba(0, 0, 0, 0.12);
  --mtx-datetimepicker-clock-cell-text-color: var(--text-neutral-body);
  --mtx-datetimepicker-clock-cell-hover-state-background-color: rgba(
    0,
    0,
    0,
    0.04
  );
  --mtx-datetimepicker-clock-cell-disabled-state-text-color: var(
    --text-neutral-disabled
  );
  --mtx-datetimepicker-time-input-text-color: var(--text-neutral-body);
  --mtx-datetimepicker-time-input-background-color: rgba(0, 0, 0, 0.12);
  --mtx-datetimepicker-time-input-focus-state-background-color: var(
    --surface-neutral-light
  );
  --mtx-datetimepicker-time-input-warn-state-outline-color: var(
    --critical-border
  );
  --mtx-datetimepicker-time-ampm-text-color: var(--text-neutral-body);
  --mtx-datetimepicker-time-ampm-outline-color: var(--border-neutral-default);
  --mtx-datetimepicker-time-ampm-selected-state-text-color: var(
    --text-neutral-invert
  );
  --mdc-text-button-label-text-color: var(--button-subtle-label-default);
  .mtx-calendar-body-cell:not(.mtx-calendar-body-disabled):hover
    > .mtx-calendar-body-cell-content:not(.mtx-calendar-body-selected) {
    color: var(--text-neutral-body);
  }
  .mtx-clock-cell.mtx-clock-cell-selected {
    color: var(--text-neutral-invert);
  }
  .mat-calendar-period-button {
    --mdc-text-button-label-text-color: var(--button-subtle-label-default);
  }
  .mat-calendar-arrow {
    --mat-datepicker-calendar-period-button-icon-color: var(
      --button-subtle-label-default
    );
  }
}

.mtx-datetimepicker-content {
  box-shadow: var(--mtx-datetimepicker-container-elevation-shadow);
}

.mtx-clock-wrapper {
  @apply surface-brand-lighter;
}

.mtx-datetimepicker-toggle {
  .mat-mdc-button-ripple {
    display: none;
  }
}

.mtx-time-input {
  @apply caret-cca-neutrals-800 text-neutral-body surface-brand-light;
  &.mtx-time-input-active {
    @apply caret-cca-neutrals-800 text-neutral-body surface-brand-lighter;

    &:focus {
      @apply caret-cca-neutrals-800 text-neutral-body surface-brand-lighter;

      &::placeholder {
        @apply caret-cca-neutrals-500 text-neutral-caption surface-brand-lighter;
      }
    }
  }
}

.mtx-time-ampm {
  @apply border-neutral-dark;

  :is(.mtx-time-am, .mtx-time-pm) {
    @apply surface-neutral-dark text-neutral-body;

    &.mtx-time-ampm-active {
      @apply surface-brand-light;
    }

    &:focus {
      @apply outline outline-1 outline-cca-green-400;
    }
  }
}

.mtx-calendar-controls .mat-mdc-icon-button svg {
  z-index: 1;
}

.mtx-datetimepicker-toggle button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.25rem;
}
