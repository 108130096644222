:root {
  --mat-select-enabled-trigger-text-color: var(--text-neutral-body);
  --mat-select-placeholder-text-color: var(--text-neutral-caption);
  --mat-select-panel-background-color: var(--surface-neutral-light);
  --mat-option-label-text-color: var(--text-neutral-body);
  --mat-select-enabled-arrow-color: var(--form-icon);
  --mat-select-disabled-arrow-color: var(--form-disabled);
  --mat-select-disabled-trigger-text-color: var(--form-disabled);

  --mat-standard-button-toggle-background-color: var(--surface-neutral-light);
  --mat-standard-button-toggle-divider-color: var(--border-neutral-default);
  --mat-standard-button-toggle-text-color: var(--text-neutral-body);

  --mat-select-container-elevation-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-mdc-select-trigger {
  .mat-mdc-select-arrow {
    width: 1.25rem;
    height: 1.25rem;
    &::after {
      padding: 0;
      border: none;
      transform: none;
      content: '\e930'; // chevron-down icon
      font-size: 1.25rem;
      display: block;
      font-family: CtrlChainIcons;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      display: none;
    }
  }
}
