:root {
  --mat-sidenav-content-text-color: var(--text-neutral-body);
  --mat-sidenav-container-text-color: var(--text-neutral-body);
  --mat-sidenav-container-background-color: var(--surface-neutral-light);
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow:
    0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}
